import React, { Component } from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// -------------------- Helpers --------------------

const getItems = (count) =>
  Array.from({ length: count }, (v, k) => ({
    id: `item-${k}`,
    primary: `Item ${k}`,
    secondary: k % 2 === 0 ? `Extra info pentru ${k}` : undefined
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  ...(isDragging && {
    background: 'rgb(235,235,235)'
  })
});

// -------------------- Componente DnD --------------------

export const DraggableComponent = (id, index) => (props) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  );
};

export const DroppableComponent =
  (onDragEnd) =>
  (props) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-1" direction="vertical">
          {(provided) => (
            <TableBody
              ref={provided.innerRef}
              {...provided.droppableProps}
              {...props}
            >
              {props.children}
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

// -------------------- Componenta principală --------------------

export default class DndTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: getItems(10)
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    if (!result.destination) return;

    console.log(`dragEnd: mutăm itemul de la ${result.source.index} la ${result.destination.index}`);
    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({ items });
  }

  render() {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nr. Crt.</TableCell>
              <TableCell>Label</TableCell>
              <TableCell align="right">Text</TableCell>
            </TableRow>
          </TableHead>

          <TableBody component={DroppableComponent(this.onDragEnd)}>
            {this.state.items.map((item, index) => (
              <TableRow
                component={DraggableComponent(item.id, index)}
                key={item.id}
              >
                <TableCell scope="row">{index + 1}</TableCell>
                <TableCell>{item.primary}</TableCell>
                <TableCell align="right">{item.secondary || ''}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}
