import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@mui/material';
//
import { BaseOptionChart } from '../../charts';

import { backendUrl } from '../../../index';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function GraphWebsiteVisitsByDay({ restaurant }) {
  const { t } = useTranslation();
  const [usersByDay, setUsersByDay] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  async function getUniqueUsersByDay() {
    try {
      setIsLoading(true);
      const currentDate = Date.now();
      const xDaysAgoDate = currentDate - 7 * 24 * 60 * 60 * 1000;

      const response = await fetch(
        `${backendUrl}/aaaa/uniqueUsers/online/daily?page=www.reach-menu.com/${restaurant.urlKey}&restaurantKey=${restaurant.urlKey}&startDate=${xDaysAgoDate}&endDate=${currentDate}`
      );

      const data = await response.json();

      setUsersByDay({
        ...data,
        usersByDay: data.usersByDay || {}
      });
    } catch (error) {
      console.error('Eroare la fetch:', error);
      setUsersByDay({ usersByDay: {} });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getUniqueUsersByDay();
  }, []);

  if (isLoading) {
    return <div>{t('loading')}</div>;
  }
  if (!usersByDay || !usersByDay.usersByDay) {
    return <div>{t('loading')}</div>;
  }

  const CHART_DATA = [
    {
      name: t('visitors'),
      data: Object.values(usersByDay.usersByDay)
    }
  ];

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: '31%', borderRadius: 4 } },
    legend: { show: true },
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels: Object.keys(usersByDay.usersByDay),
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} ${t('visits')}`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title={t('onlineVisitors')} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="bar"
          series={CHART_DATA}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
}
