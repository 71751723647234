import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
import { useContext } from '../../context/general/context';
import { logout } from '../../context/general/actions';
import { useTranslation } from 'react-i18next';
import MenuPopover from '../../components/MenuPopover';
// mock - pentru fallback
import account from '../../_mocks_/account';

const MENU_OPTIONS = [
  {
    labelKey: 'menu.home',
    icon: homeFill,
    linkTo: '/'
  },
  {
    labelKey: 'menu.profile',
    icon: personFill,
    linkTo: 'profile'
  },
  {
    labelKey: 'menu.settings',
    icon: settings2Fill,
    linkTo: 'settings'
  }
];

export default function AccountPopover() {
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const { state, dispatch } = useContext();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const userPhotoURL =
    state.userDetails?.photoURL || account.photoURL;
  const userFirstName =
    state.userDetails?.firstName || account.displayName || 'Guest';
  const userLastName =
    state.userDetails?.lastName || '';
  const userSub =
    state.userDetails?.sub || '';

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          position: 'relative',
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={userPhotoURL} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {userFirstName} {userLastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userSub}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.labelKey}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{ mr: 2, width: 24, height: 24 }}
            />
            {t(option.labelKey)}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => logout(dispatch)}
          >
            {t('menu.logout')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
