// scroll bar
import React, { Suspense, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import 'simplebar/src/simplebar.css';
import { ContextProvider, useContext } from './context/general/context';
//
import { ref } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

import { getDownloadURL, getStorage, uploadBytesResumable } from "firebase/storage";
import ThemeConfig from './theme';

import App from './App';
import { FeatureProvider } from './context/feature/context';
import { logout } from './context/general/actions';
import './i18n';
import reportWebVitals from './reportWebVitals';

import { LanguageProvider } from './context/language/context';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCrquM1O0VjQ-Vk71_2W7JghniiqyCqUMY",
  authDomain: "skilful-grove-331223.firebaseapp.com",
  projectId: "skilful-grove-331223",
  storageBucket: "skilful-grove-331223.appspot.com",
  messagingSenderId: "934399458839",
  appId: "1:934399458839:web:2f130bcbc78992bdb2e652"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const storage = getStorage(firebase);

async function uploadTaskPromise(file, currentRestaurantId) {
  return new Promise(function (resolve, reject) {
    const storageRef = ref(storage, currentRestaurantId + '/' + uuidv4());
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      'state_changed',
      function (snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },
      function error(err) {
        console.log('error', err);
        reject();
      },
      function complete() {
        getDownloadURL(uploadTask.snapshot.ref).then(function (downloadURL) {
          console.log(downloadURL);
          console.log(decodeURIComponent(downloadURL).substring(79, 79 + 73));

          const uri =
            "https://ik.imagekit.io/lprqlslc6bt/" +
            decodeURIComponent(downloadURL).substring(79, 79 + 73);
          resolve(uri);
        });
      }
    );
  });
}

export default uploadTaskPromise;

export const backendUrl = "https://reach-main.herokuapp.com";
//export const backendUrl = "http://localhost:8080";
// export const backendUrl = "https://reach-main-mtglug2i2e-aez.run.app";

const WithFetch = ({ children }) => {
  const { state, dispatch } = useContext();

  useMemo(() => {
    fetch = (originalFetch => {
      return async (...args) => {
        const token = localStorage.getItem("currentUser")
          ? JSON.parse(localStorage.getItem("currentUser")).token
          : "";

        console.log(args);

        if (args.length < 2) {
          args.push({ headers: { Authorization: `Bearer ${token}` } });
        } else {
          if (args[1].headers) {
            args[1].headers['Authorization'] = `Bearer ${token}`;
          }
        }
        const result = await originalFetch.apply(this, args);
        // console.log(result);
        if (result.status === 401) {
          logout(dispatch);
        }
        return result;
      };
    })(fetch);
  }, [state, dispatch]);

  return children;
};

const checkAuth = () => {
  const currentUser = localStorage.getItem('currentUser');
  if (!currentUser) {
    const isLoginPage = window.location.pathname === '/login';
    const isRegisterPage = window.location.pathname === '/register';
    
    if (!isLoginPage && !isRegisterPage) {
      window.location.href = '/login';
    }
  }
};

checkAuth();

if (process.env.NODE_ENV !== 'test') {
  const root = document.getElementById('root');
  if (root) {
    ReactDOM.render(
      <Suspense fallback={<div>Loading...</div>}>
        <HelmetProvider>
          <BrowserRouter>
            <ThemeConfig>
              <FeatureProvider>
                <ContextProvider>
                  <LanguageProvider>
                    <WithFetch>
                      <App />
                    </WithFetch>
                  </LanguageProvider>
                </ContextProvider>
              </FeatureProvider>
            </ThemeConfig>
          </BrowserRouter>
        </HelmetProvider>
      </Suspense>,
      document.getElementById('root')
    );
  }
}
// If you want to enable client cache, register instead.
// serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();