import PropTypes from 'prop-types';
// material
import { MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

BlogPostsSort.propTypes = {
  options: PropTypes.array,
  onSort: PropTypes.func
};

export default function BlogPostsSort({ options, onSort }) {
  const { t } = useTranslation();

  const translatedOptions = options.map((option) => ({
    ...option,
    label: t(option.label)
  }));

  return (
    <TextField select size="small" value="latest" onChange={onSort}>
      {translatedOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
