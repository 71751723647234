import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../components/_dashboard/blog';
//
import POSTS from '../_mocks_/blog';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', labelKey: 'blog.sortOptions.latest' },
  { value: 'popular', labelKey: 'blog.sortOptions.popular' },
  { value: 'oldest', labelKey: 'blog.sortOptions.oldest' }
];

// ----------------------------------------------------------------------

export default function Blog() {
  const { t } = useTranslation();

  return (
    <Page title={t('blog.pageTitle')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('blog.title')}
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            {t('blog.newPost')}
          </Button>
        </Stack>

        <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          <BlogPostsSearch posts={POSTS} placeholder={t('blog.searchPlaceholder')} />
          <BlogPostsSort
            options={SORT_OPTIONS.map((option) => ({
              value: option.value,
              label: t(option.labelKey)
            }))}
          />
        </Stack>

        <Grid container spacing={3}>
          {POSTS.map((post, index) => (
            <BlogPostCard key={post.id} post={post} index={index} />
          ))}
        </Grid>
      </Container>
    </Page>
  );
}