import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, CircularProgress } from '@mui/material';
import { MHidden } from '../../components/@material-extend';
// context
import { useContext } from '../../context/general/context';
import { useFeature, LANGUAGE } from '../../context/feature/context';
// fetch
import { backendUrl } from '../../index';
import useFetch from 'use-http';
// react
import { useRef, useState, useEffect } from 'react';
// components
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import CurrentRestaurrantPopover from './CurrentRestaurrantPopover';
import NotificationsPopover from './NotificationsPopover';
import AddRestaurantButton from './AddRestaurantButton';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { state } = useContext();
  const { state: featureState, dispatch: featureDispatch } = useFeature();
  const [restaurantSimple, setRestaurantSimple] = useState(null);

  const restaurantFetch = useFetch(`${backendUrl}/restaurants`);

  async function loadRestaurantSimple() {
    const response = await restaurantFetch.get(
      `${state.currentRestaurantId}?simple=true`
    );
    if (restaurantFetch.response.ok) {
      setRestaurantSimple(response);
    }
  }

  useEffect(() => {
    loadRestaurantSimple();
  }, [state.language, state.currentRestaurantId]);

  useEffect(() => {
    if (restaurantSimple && restaurantSimple.features) {
      featureDispatch({ type: 'INIT', payload: restaurantSimple.features });
    }
  }, [restaurantSimple, featureDispatch]);

  if (!restaurantSimple) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const languageFeature = featureState?.features?.[LANGUAGE] || null;

  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Searchbar />

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AddRestaurantButton />

          {languageFeature && (
            <LanguagePopover languages={languageFeature.split(',')} />
          )}

          <CurrentRestaurrantPopover />
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
