import { useRef, useState, useEffect } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, ListItemIcon, ListItemText, IconButton, Avatar } from '@mui/material';
// context general
import { useContext } from '../../context/general/context';
import { changeCurrentRestaurant } from '../../context/general/actions';
// fetch & backend
import { backendUrl } from '../../index';
// components
import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------

export default function CurrentRestaurantPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useContext();

  const [restaurants, setRestaurants] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  async function loadRestaurants() {
    if (state.userDetails && state.userDetails.sub) {
      try {
        const response = await fetch(
          `${backendUrl}/restaurants?staffUser=${state.userDetails.sub}`
        );
        const json = await response.json();
        if (response.ok) {
          setRestaurants(json);
          if (json.current) {
            changeCurrentRestaurant(dispatch, json.current.id);
          }
        } else {
          console.error('Failed to load restaurants:', json);
        }
      } catch (error) {
        console.error('Error fetching restaurants:', error);
      }
    }
  }

  async function changeActiveRestaurant(restaurantId) {
    if (!state.userDetails || !state.userDetails.sub) {
      console.error('User details not available');
      return;
    }
    
    try {
      const response = await fetch(`${backendUrl}/restaurants/${restaurantId}/set-current`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          staffMember: state.userDetails.sub,
          restaurantId
        })
      });
      if (response.ok) {
        changeCurrentRestaurant(dispatch, restaurantId);
        handleClose();
        window.location.reload();
      } else {
        const errorData = await response.json();
        console.error('Failed to set current restaurant:', errorData);
      }
    } catch (error) {
      console.error('Error setting current restaurant:', error);
    }
  }

  useEffect(() => {
    if (state.userDetails && state.userDetails.sub) {
      loadRestaurants();
    }
  }, [state.globalUpdate, state.userDetails]);

  if (!state.userDetails || !state.userDetails.sub || !restaurants) return null;

  const currentRestaurant = restaurants.current;

  const getInitial = (name) => {
    if (!name) return '?';
    return name.charAt(0).toUpperCase();
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        {currentRestaurant ? (
          currentRestaurant.profileUrlImg ? (
            <Avatar alt={currentRestaurant.name} src={currentRestaurant.profileUrlImg} />
          ) : (
            <Avatar sx={{ bgcolor: 'primary.main', color: 'white' }}>
              {getInitial(currentRestaurant.name)}
            </Avatar>
          )
        ) : (
          <Avatar />
        )}
      </IconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {restaurants.list.map((option) => (
            <MenuItem
              key={option.id}
              selected={option.id === state.currentRestaurantId}
              onClick={() => changeActiveRestaurant(option.id)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                {option.profileUrlImg ? (
                  <Avatar alt={option.name} src={option.profileUrlImg} />
                ) : (
                  <Avatar sx={{ bgcolor: 'primary.main', color: 'white' }}>
                    {getInitial(option.name)}
                  </Avatar>
                )}
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.name}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
