import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  CircularProgress
} from '@mui/material';
// context
import { useContext } from '../context/general/context';
import { backendUrl } from '../index';
// i18n
import { useTranslation } from 'react-i18next';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import TablesListHead from '../components/_dashboard/tables/TablesListHead';
import TablesMoreMenu from '../components/_dashboard/tables/TablesMoreMenu';
import EditableCell from '../components/_dashboard/categories/EditableCell';
import QRButton from '../components/_dashboard/tables/qr/QRButton';

// ----------------------------------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

// ----------------------------------------------------------------------

export default function User() {
  const { t } = useTranslation();
  const { state } = useContext();

  const [updated, setUpdated] = useState(false);
  const [USERLIST, setUserList] = useState(null);

  const TABLE_HEAD = [
    { id: 'tableNumber', label: 'tableNumber', alignRight: false },
    { id: 'preview', label: 'preview', alignRight: false },
    { id: 'qrCode', label: 'qrCode', alignRight: false },
    { id: 'more', label: 'more', alignRight: true }
  ];

  useEffect(() => {
    async function loadTables() {
      if (state.currentRestaurantId !== '') {
        const response = await fetch(
          `${backendUrl}/restaurants/${state.currentRestaurantId}/tables`
        );
        const json = await response.json();
        if (response.ok) {
          setUserList(json);
        } else {
          setUserList([]);
        }
      } else {
        setUserList([]);
      }
    }
    loadTables();
  }, [state.currentRestaurantId, updated, state.globalUpdate]);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked && USERLIST) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = [...selected, name];
    } else if (selectedIndex === 0) {
      newSelected = [...selected.slice(1)];
    } else if (selectedIndex === selected.length - 1) {
      newSelected = [...selected.slice(0, -1)];
    } else if (selectedIndex > 0) {
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1)
      ];
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!USERLIST) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size={70} />
      </Box>
    );
  }

  const filteredUsers = USERLIST;
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - filteredUsers.length)
      : 0;

  return (
    <Page title={t('tables.pageTitle')}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            {t('tables.title')}
          </Typography>
          <Button
            disabled={state.currentRestaurantId === ''}
            variant="contained"
            component={RouterLink}
            to="/dashboard/add-table"
            startIcon={<Icon icon={plusFill} />}
          >
            {t('tables.addTable')}
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TablesListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredUsers.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {filteredUsers
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, i) => {
                      const { id, name } = row;
                      const isItemSelected =
                        selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left">{i}</TableCell>

                          <EditableCell
                            endpoint={`/tables/${id}`}
                            row={row}
                            column="name"
                          />

                          <TableCell align="left">
                            <Button
                              variant="outlined"
                              onClick={() =>
                                window.open(
                                  `https://reach-menu.com/restaurants/${state.currentRestaurantId}/tables/${id}`,
                                  '_blank'
                                )
                              }
                              startIcon={<Icon icon="mdi:web" />}
                            >
                              {t('tables.preview')}
                            </Button>
                          </TableCell>

                          <TableCell align="left">
                            <QRButton tableId={id} />
                          </TableCell>

                          <TableCell align="right">
                            <TablesMoreMenu
                              endpoint={`tables/${id}/soft-delete`}
                              setUpdated={setUpdated}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            labelRowsPerPage={t('tablee.labelRowsPerPage')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${t('tablee.of')} ${count !== -1 ? count : t('tablee.moreThan')}`
            }
            rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
