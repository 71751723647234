import {backendUrl} from '../../index'
import jwt_decode from 'jwt-decode';

export async function loginUser(dispatch, loginPayload) {
    console.log('login')
    console.log(loginPayload)
    const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(loginPayload),
	};

	try {
		dispatch({ type: 'REQUEST_LOGIN' });
		let response = await fetch(`${backendUrl}/authenticate`, requestOptions);
		let data = await response.json();

		if (data.token) {
			dispatch({ type: 'LOGIN_SUCCESS', payload: data });
			localStorage.setItem('currentUser', JSON.stringify(data));
			return data;
		}

		dispatch({ type: 'LOGIN_ERROR', error: data.error });
		console.log(data.error);
		return;
	} catch (error) {
		dispatch({ type: 'LOGIN_ERROR', error: error });
		console.log(error);
	}
}

export async function logout(dispatch) {
	localStorage.removeItem('currentUser');
	dispatch({ type: 'LOGOUT' });
	window.location.href = '/login';
}

export async function changeCurrentRestaurant(dispatch, restaurantId) {
	dispatch({ type: 'CHANGE_CURRENT_RESTAURANT', payload: {restaurantId} });
}

export async function deleteAccount(dispatch) {
	const currentUser = JSON.parse(localStorage.getItem('currentUser'));
	
	if (!currentUser || !currentUser.token) {
		dispatch({ type: 'DELETE_ACCOUNT_ERROR', error: 'No authenticated user' });
		return false;
	}

	let userEmail;
	try {
		const decodedToken = jwt_decode(currentUser.token);
		userEmail = decodedToken.sub;
	} catch (error) {
		console.error('Error decoding token:', error);
		dispatch({ type: 'DELETE_ACCOUNT_ERROR', error: 'Invalid token format' });
		return false;
	}
	
	if (!userEmail) {
		dispatch({ type: 'DELETE_ACCOUNT_ERROR', error: 'User email not found in token' });
		return false;
	}

	try {
		dispatch({ type: 'REQUEST_DELETE_ACCOUNT' });
		console.log('Starting account deletion process for:', userEmail);

		const requestOptions = {
			method: 'POST',
			headers: { 
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${currentUser.token}`
			},
			body: JSON.stringify({ 
				email: userEmail,
				deleteAssociatedData: true
			})
		};

		console.log('Sending delete account request to backend...');
		let response = await fetch(`${backendUrl}/api/delete(modifi)Account`, requestOptions);
		console.log('Account deletion response status:', response.status);
		
		let data;
		try {
			data = await response.json();
			console.log('Account deletion response data:', data);
		} catch (e) {
			console.warn('Could not parse response JSON:', e);
			data = { error: response.statusText };
		}
		
		if (response.ok) {
			console.log('Account successfully deleted');
			localStorage.removeItem('currentUser');
			
			dispatch({ type: 'DELETE_ACCOUNT_SUCCESS' });
			
			window.location.href = '/login';
			
			return true;
		} else {
			let errorMessage;
			switch (response.status) {
				case 400:
					errorMessage = 'Bad request. Please check your account information.';
					break;
				case 401:
					errorMessage = 'Authentication failed. Please log in again.';
					break;
				case 403:
					errorMessage = 'You are not authorized to delete this account.';
					break;
				case 404:
					errorMessage = 'Account not found.';
					break;
				case 405:
					errorMessage = 'Method not allowed. The server does not support this operation.';
					break;
				case 500:
					errorMessage = 'Server error. Please try again later.';
					break;
				default:
					errorMessage = data.error || 'Failed to delete account';
			}
			
			console.error('Account deletion failed:', errorMessage);
			dispatch({ type: 'DELETE_ACCOUNT_ERROR', error: errorMessage });
			return false;
		}
	} catch (error) {
		console.error('Error in delete account process:', error);
		dispatch({ type: 'DELETE_ACCOUNT_ERROR', error: error.message || 'Network error. Please check your connection.' });
		return false;
	}
}

export function checkAuth() {
	const currentUser = localStorage.getItem('currentUser');
	
	if (!currentUser) {
		const isLoginPage = window.location.pathname === '/login';
		const isRegisterPage = window.location.pathname === '/register';
		const is404Page = window.location.pathname === '/404';
		
		if (!isLoginPage && !isRegisterPage && !is404Page) {
			window.location.href = '/login';
			return false;
		}
	}
	
	return !!currentUser;
}

