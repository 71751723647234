import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, Avatar, TextField, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useFetch from 'use-http';
import uploadTaskPromise, { backendUrl } from '../../../index';
import { useContext } from '../../../context/general/context';
import { changeCurrentRestaurant } from '../../../context/general/actions';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------
import Upload from '../products/uploader/Upload';

export default function AddRestaurantForm() {
  const { t } = useTranslation();
  const { get, post, response, error } = useFetch(backendUrl);
  const navigate = useNavigate();

  const [imgUrlProfile, setImgUrlProfile] = useState();
  const [imgUrlCover, setImgUrlCover] = useState();

  const [imageProfile, setImageProfile] = useState();
  const [imageCover, setImageCover] = useState();

  const RegisterSchema = Yup.object().shape({
    staffMember: Yup.string().required(t('validation.staffMemberRequired')),
    name: Yup.string()
      .min(1, t('validation.nameTooShort'))
      .max(50, t('validation.nameTooLong'))
      .required(t('validation.nameRequired')),
    profileUrlImg: Yup.string(),
    coverUrlImg: Yup.string(),
    description: Yup.string()
      .min(1, t('validation.descriptionTooShort'))
      .max(255, t('validation.descriptionTooLong'))
      .required(t('validation.descriptionRequired')),
  });

  async function uploadImage(image, setImgUrl) {
    const imageUrl = await uploadTaskPromise(image);
    setImgUrl(imageUrl);
    return imageUrl;
  }

  const { state, dispatch } = useContext();

  const formik = useFormik({
    initialValues: {
      staffMember: state.userDetails.sub,
      name: '',
      profileUrlImg: '',
      coverUrlImg: '',
      description: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      try {
        let urlProfile = '';
        let urlCover = '';

        if (imageProfile) {
          urlProfile = await uploadImage(imageProfile, setImgUrlProfile);
        }
        if (imageCover) {
          urlCover = await uploadImage(imageCover, setImgUrlCover);
        }

        const newRestaurant = await post('/restaurants', {
          ...values,
          profileUrlImg: urlProfile,
          coverUrlImg: urlCover,
        });

        if (response.ok) {
          changeCurrentRestaurant(dispatch, newRestaurant.id);
          navigate('/dashboard/tables', { replace: true });
        }
      } catch (err) {
        console.error('Error creating restaurant:', err);
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label={t('form.name')}
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />

          <TextField
            fullWidth
            label={t('form.description')}
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
          />

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Upload setImage={setImageProfile} text={t('form.addProfilePicture')} />
            {imageProfile && (
              <Avatar
                src={URL.createObjectURL(imageProfile)}
                variant="square"
                sx={{ width: '10%', height: '100%' }}
              />
            )}
            <Upload setImage={setImageCover} text={t('form.addCoverPicture')} />
            {imageCover && (
              <Avatar
                src={URL.createObjectURL(imageCover)}
                variant="square"
                sx={{ width: '10%', height: '100%' }}
              />
            )}
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('form.addButton')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
