import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { Container, Stack, Typography } from '@mui/material';
import EditProductForm from '../components/_dashboard/products/EditProductForm';

// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function EditProduct() {
  const { state } = useLocation();
  const { t } = useTranslation();

  const { id, name, imgUrl, price, colors, status } = state;

  console.log("state", state);

  return (
    <Page title={t('dashboard.editProductPageTitle')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('dashboard.editProductHeading')}
          </Typography>
        </Stack>

        <EditProductForm product={state} />
      </Container>
    </Page>
  );
}
