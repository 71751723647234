import jwt_decode from "jwt-decode";

let token = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")).token
    : "";
let user = localStorage.getItem("currentUser")
    ? jwt_decode(token)
    : "";

export const initialState = {
    globalUpdate: Date.now(),
    userDetails: "" || user,
    token: "" || token,
    currentRestaurantId: "",
    loading: false,
    logged: false || !!token,
    errorMessage: null,
    language: 'ro',
    position: null,
};

export function reducer(initialState, action) {
    switch (action.type) {
        case "REQUEST_LOGIN":
            return {
                ...initialState,
                loading: true
            };
        case "LOGIN_SUCCESS":
            return {
                ...initialState,
                userDetails: jwt_decode(action.payload.token),
                token: action.payload.token,
                loading: false,
                logged: true
            };
        case "LOGOUT":
            return {
                ...initialState,
                userDetails: "",
                token: "",
                logged: false,
                currentRestaurantId: "",
                position: null,
            };

        case "LOGIN_ERROR":
            return {
                ...initialState,
                loading: false,
                errorMessage: action.error,
                logged: false
            };

        case "CHANGE_CURRENT_RESTAURANT":
            console.log("action reducer");
            console.log(action);
            return {
                ...initialState,
                currentRestaurantId: action.payload.restaurantId
            };

        case "GLOBAL_UPDATE":
            return {
                ...initialState,
                globalUpdate: Date.now()
            };
        case 'setPosition':
            return { ...initialState, position: action.payload }

        case 'setLanguage':
            return { 
                ...initialState, 
                language: action.payload 
            };

        case 'setPosition':
            return {
                ...initialState,
                position: action.payload
            };

        case 'REQUEST_DELETE_ACCOUNT':
            return {
                ...initialState,
                loading: true
            };
        case 'DELETE_ACCOUNT_SUCCESS':
            return {
                ...initialState,
                userDetails: null,
                loading: false,
                logged: false
            };
        case 'DELETE_ACCOUNT_ERROR':
            return {
                ...initialState,
                loading: false,
                error: action.error
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};