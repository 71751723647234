import {
    TableCell,
    Input,
    IconButton,
    CircularProgress
} from '@mui/material';
import { useState } from 'react';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { backendUrl } from '../../../index';
import { useContext } from '../../../context/general/context';
import { useTranslation } from 'react-i18next';

export default function EditableCell({ endpoint, row, column, method = "PUT" }) {
    const { t } = useTranslation();
    const [value, setValue] = useState(row[column]);
    const [isEditMode, setIsEditMode] = useState(false);

    const [loading, setLoading] = useState(false);

    const { dispatch } = useContext();

    const onChange = (event) => {
        setIsEditMode(true);
        setValue(event.target.value);
    };

    async function onSave() {
        setLoading(true);

        row[column] = value;

        await fetch(backendUrl + endpoint, {
            method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(row)
        });

        setLoading(false);
        dispatch({ type: "GLOBAL_UPDATE" });
        setIsEditMode(false);
    }

    return (
        <TableCell align="left">
            <Input
                value={value}
                name={column}
                onChange={(e) => onChange(e)}
            />
            {isEditMode && (
                <IconButton
                    sx={{ color: "#00c853" }}
                    aria-label={t('save')}
                    onClick={onSave}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : <DoneOutlineIcon />}
                </IconButton>
            )}
        </TableCell>
    );
}