import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Stack, Typography, Alert, CircularProgress, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditUserForm from '../components/_dashboard/user/EditUserForm';
import Page from '../components/Page';
import { useContext } from '../context/general/context';
import { backendUrl } from '../index';

// ----------------------------------------------------------------------

export default function EditUser() {
  const [openFilter, setOpenFilter] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const { state } = useContext();
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('id');

  useEffect(() => {
    async function fetchUserData() {
      if (!userId || !state.currentRestaurantId) {
        setError('User ID or restaurant ID is missing');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`${backendUrl}/restaurants/${state.currentRestaurantId}/staff`);
        
        if (!response.ok) {
          throw new Error(`Failed to fetch user data: ${response.statusText}`);
        }
        
        const staffList = await response.json();
        const staffMember = staffList.find(staff => staff.id === userId);
        
        if (!staffMember) {
          throw new Error(`User with ID ${userId} not found`);
        }
        
        setUserData(staffMember);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError(err.message || 'Failed to fetch user data');
        setLoading(false);
      }
    }

    fetchUserData();
  }, [userId, state.currentRestaurantId]);

  const formik = useFormik({
    initialValues: {
      gender: '',
      category: '',
      colors: '',
      priceRange: '',
      rating: ''
    },
    onSubmit: () => {
      setOpenFilter(false);
    }
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress size={70} />
      </Box>
    );
  }

  if (error) {
    return (
      <Page title={t('dashboard.editUserPageTitle')}>
        <Container>
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
          <Typography variant="body1">
            Unable to load user data. Please try again later or contact support.
          </Typography>
        </Container>
      </Page>
    );
  }

  return (
    <Page title={t('dashboard.editUserPageTitle')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('dashboard.editUser')}
          </Typography>
        </Stack>

        {userData && <EditUserForm userData={userData} />}
      </Container>
    </Page>
  );
} 