import React, { useState } from 'react';
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Stack,
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import Page from '../components/Page';
import { useContext } from '../context/general/context';
import { deleteAccount } from '../context/general/actions';
import { backendUrl } from '../index';
import { useTranslation } from 'react-i18next';

const RootStyle = styled(Page)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8)
}));

const DeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
}));

export default function Settings() {
  const navigate = useNavigate();
  const { state, dispatch } = useContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation();

  const handleDeleteAccount = async () => {
    setIsDeleting(true);
    try {
      const success = await deleteAccount(dispatch);
      if (success) {
        navigate('/login');
      } else {
        throw new Error('Failed to delete account');
      }
    } catch (err) {
      setError(err.message || 'An unexpected error occurred. Please try again later.');
      setOpenDialog(false);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <RootStyle title="Settings | Reach Admin">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {t('account.settings')}
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}

        <Card>
          <CardContent>
            <Stack spacing={3}>
              <Typography variant="h6" color="text.secondary">
                {t('account.management')}
              </Typography>
              
              <DeleteButton
                variant="contained"
                onClick={() => setOpenDialog(true)}
                sx={{ width: 'fit-content' }}
                disabled={isDeleting}
                startIcon={<DeleteIcon />}
              >
                {isDeleting ? <CircularProgress size={24} color="inherit" /> : t('account.delete.button')}
              </DeleteButton>
            </Stack>
          </CardContent>
        </Card>

        <Dialog
          open={openDialog}
          onClose={() => !isDeleting && setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <WarningIcon color="error" />
            {t('account.delete.confirmation')}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              {t('account.delete.warning')}
            </Typography>
            
            <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }}>
              {t('account.delete.followingWillBeDeleted')}:
            </Typography>
            
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText primary={t('account.delete.yourAccount')} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText primary={t('account.delete.allYourRestaurants')} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText primary={t('account.delete.allMenuItems')} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText primary={t('account.delete.allCategories')} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText primary={t('account.delete.allTables')} />
              </ListItem>
            </List>
            
            <Alert severity="warning" sx={{ mt: 2 }}>
              {t('account.delete.actionCannotBeUndone')}
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setOpenDialog(false)}
              disabled={isDeleting}
            >
              {t('cancel')}
            </Button>
            <Button 
              onClick={handleDeleteAccount}
              color="error"
              variant="contained"
              disabled={isDeleting}
              startIcon={isDeleting ? <CircularProgress size={20} /> : <DeleteIcon />}
            >
              {isDeleting ? t('account.delete.deleting') : t('account.delete.confirmButton')}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </RootStyle>
  );
}