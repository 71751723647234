import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Page from '../components/Page';
import AdminFeatureUnavailable from '../components/_dashboard/features/AdminFeatureUnavailable';
import AdminFeatureAvailable from '../components/_dashboard/features/AdminFeatureAvailable';
import { backendUrl } from '../index';
import { useContext } from '../context/general/context';

export default function AdminFeature() {
  const { state: feature } = useLocation();
  const { t } = useTranslation();

  const [restaurants, setRestaurants] = useState();
  const [restaurantsAvailable, setRestaurantsAvailable] = useState();

  const { state } = useContext();

  useEffect(() => {
    const fetchRestaurants = async () => {
      if (state.currentRestaurantId) {
        const response = await fetch(`${backendUrl}/restaurants`);
        const json = await response.json();
        if (response.ok) {
          setRestaurants(json);
        }
      } else {
        setRestaurants([]);
      }
    };
    fetchRestaurants();
  }, [state.currentRestaurantId, state.globalUpdate]);

  useEffect(() => {
    const fetchRestaurantsFeatures = async () => {
      if (state.currentRestaurantId) {
        const response = await fetch(`${backendUrl}/restaurants-features/${feature.id}/restaurants`);
        const json = await response.json();
        if (response.ok) {
          const map = new Map();
          json.forEach((restaurantFeature) => map.set(restaurantFeature.restaurantId, restaurantFeature.id));
          setRestaurantsAvailable(map);
        }
      } else {
        setRestaurantsAvailable(new Map());
      }
    };
    fetchRestaurantsFeatures();
  }, [state.currentRestaurantId, state.globalUpdate]);

  if (!restaurants) return t('loading');
  if (!restaurantsAvailable) return t('loading');

  const restaurantsWithFeature = restaurants
    .filter((restaurant) => restaurantsAvailable.has(restaurant.id))
    .map((restaurant) => ({ ...restaurant, restaurantFeatureId: restaurantsAvailable.get(restaurant.id) }));

  const restaurantsWithoutFeature = restaurants.filter((restaurant) => !restaurantsAvailable.has(restaurant.id));

  return (
    <Page title={t('adminFeature.pageTitle')}>
      <AdminFeatureUnavailable USERLIST={restaurantsWithoutFeature} feature={feature} />
      <Box mb={10} />
      <AdminFeatureAvailable USERLIST={restaurantsWithFeature} feature={feature} />
    </Page>
  );
}
