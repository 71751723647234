import { Icon } from '@iconify/react';
import { useState } from 'react';
import chevronUpFill from '@iconify/icons-eva/chevron-up-fill';
import chevronDownFill from '@iconify/icons-eva/chevron-down-fill';
// material
import { Menu, Button, MenuItem, Typography } from '@mui/material';
// i18n
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function ProductSort() {
  const { t } = useTranslation();
  const SORT_BY_OPTIONS = [
    { value: 'featured', label: t('sort.featured') },
    { value: 'newest', label: t('sort.newest') },
    { value: 'priceDesc', label: t('sort.priceDesc') },
    { value: 'priceAsc', label: t('sort.priceAsc') }
  ];

  const [open, setOpen] = useState(null);
  const [selectedSort, setSelectedSort] = useState(SORT_BY_OPTIONS[1].value);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (value) => {
    if (value) setSelectedSort(value);
    setOpen(null);
  };

  return (
    <>
      <Button
        color="inherit"
        disableRipple
        onClick={handleOpen}
        endIcon={<Icon icon={open ? chevronUpFill : chevronDownFill} />}
      >
        {t('sort.sortBy')}:&nbsp;
        <Typography component="span" variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {SORT_BY_OPTIONS.find((option) => option.value === selectedSort)?.label}
        </Typography>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={() => handleClose()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === selectedSort}
            onClick={() => handleClose(option.value)}
            sx={{ typography: 'body2' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
