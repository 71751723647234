import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useFetch from 'use-http';
import { backendUrl } from '../../../index';
import { useContext } from '../../../context/general/context';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function EditUserForm({ userData }) {
  const { get, put, response, loading, error: fetchError } = useFetch(backendUrl);
  const navigate = useNavigate();
  const { state } = useContext();
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const EditUserSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('addUserForm.validEmail'))
      .max(100, t('addUserForm.emailTooLong'))
      .required(t('addUserForm.emailRequired')),
    firstName: Yup.string()
      .min(2, t('addUserForm.firstNameTooShort'))
      .max(30, t('addUserForm.firstNameTooLong'))
      .required(t('addUserForm.firstNameRequired')),
    lastName: Yup.string()
      .min(2, t('addUserForm.lastNameTooShort'))
      .max(30, t('addUserForm.lastNameTooLong'))
      .required(t('addUserForm.lastNameRequired')),
    password: Yup.string()
      .max(50, t('addUserForm.passwordTooLong')),
    role: Yup.string()
      .max(20, t('addUserForm.roleTooLong'))
      .required(t('addUserForm.roleRequired')),
  });

  const formik = useFormik({
    initialValues: {
      email: userData.email || '',
      firstName: userData.firstName || '',
      lastName: userData.lastName || '',
      password: '',
      role: userData.role || '',
    },
    validationSchema: EditUserSchema,
    onSubmit: async (values) => {
      try {
        const updateData = { ...values };
        if (!updateData.password) {
          delete updateData.password;
        }

        const result = await put(`restaurants/${state.currentRestaurantId}/add-staff`, {
          ...updateData,
        });
        
        if (!response.ok) {
          throw new Error(result.message || 'Failed to update user');
        }
        
        setSuccess(true);
        setError(null);
        
        setTimeout(() => {
          navigate('/dashboard/user', { replace: true });
        }, 1500);
      } catch (err) {
        console.error('Error updating user:', err);
        setError(err.message || 'Failed to update user');
        setSuccess(false);
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          
          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              User updated successfully!
            </Alert>
          )}
          
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('addUserForm.firstName')}
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label={t('addUserForm.lastName')}
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete="off"
              type="email"
              label={t('addUserForm.email')}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              label={t('addUserForm.role')}
              {...getFieldProps('role')}
              error={Boolean(touched.role && errors.role)}
              helperText={touched.role && errors.role}
            />
          </Stack>

          <TextField
            fullWidth
            autoComplete="off"
            type={showPassword ? 'text' : 'password'}
            label={t('addUserForm.password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={(touched.password && errors.password) || "Leave empty to keep current password"}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('addUserForm.submit')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}