import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// material
import { LoadingButton } from '@mui/lab';
import { Avatar, CircularProgress, Stack, TextField } from '@mui/material';
import useFetch from 'use-http';
import uploadTaskPromise, { backendUrl } from '../../../index';
// context
import { useContext as useGeneralContext } from '../../../context/general/context';
import { useLanguage } from '../../../context/language/context';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import MultipleSelect from './MultipleSelect';
import Upload from './uploader/Upload';

export default function AddProductForm() {
  const { post } = useFetch(backendUrl);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { state: generalState } = useGeneralContext();
  const { currentRestaurantId } = generalState;


  const { state: langState } = useLanguage();
  const language = langState.language;

  const [image, setImage] = useState();
  const [imgUrl, setImgUrl] = useState();
  const [formState, setFormState] = useState({ items: [] });
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  const [tags, setTags] = useState([]);

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  async function uploadImage() {
    if (!image) {
      return 'http://res.cloudinary.com/daqirrhrb/image/upload/v1634771923/pn6dqajidofigjpiop1u.png';
    }
    const imageUrl = await uploadTaskPromise(image, currentRestaurantId);
    setImgUrl(imageUrl);
    return imageUrl;
  }

  useEffect(() => {
    async function fetchCategories() {
      if (currentRestaurantId !== '') {
        const response = await fetch(
          `${backendUrl}/restaurants/${currentRestaurantId}/categories${
            language === 'ro' ? '' : `?lang=${language}`
          }`
        );
        const json = await response.json();
        if (response.ok) {
          setCategories(json);
        } else {
          setCategories([]);
        }
      } else {
        setCategories([]);
      }
    }
    fetchCategories();
  }, [currentRestaurantId, language]);

  useEffect(() => {
    async function fetchTags() {
      const response = await fetch(`${backendUrl}/tags`);
      const json = await response.json();
      if (response.ok) {
        setTags(json);
      }
    }
    fetchTags();
  }, [currentRestaurantId]);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t('nameTooShort'))
      .max(100, t('nameTooLong'))
      .required(t('nameRequired')),
    categoryId: Yup.string(),
    restaurantId: Yup.string(),
    price: Yup.number()
      .min(0, t('priceTooLow'))
      .required(t('priceRequired')),
    priceDetails: Yup.string()
      .max(100, t('priceDetailsTooLong'))
      .nullable(),
    discount: Yup.number()
      .min(0, t('minValue'))
      .max(100, t('maxValue'))
      .nullable(),
    description: Yup.string()
      .min(10, t('descriptionTooShort'))
      .max(1000, t('descriptionTooLong'))
      .required(t('descriptionRequired')),
    imgUrl: Yup.string()
      .max(255, t('urlTooLong')),
    tags: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      categoryId: '',
      restaurantId: currentRestaurantId,
      price: 0,
      priceDetails: '',
      discount: 0,
      description: '',
      imgUrl: '',
      tags: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      const url = await uploadImage();
      await post(`products${language === 'ro' ? '' : `?lang=${language}`}`, {
        ...values,
        categoryId: category || (categories[0] && categories[0].id),
        imgUrl: url,
        tags: formState.items
      });
      navigate('/dashboard/products', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  if (categories.length === 0)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress size={70} />
      </Box>
    );

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('name')}
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <FormControl fullWidth>
              <InputLabel>{t('category')}</InputLabel>
              <Select
                value={category || (categories[0] && categories[0].id) || ''}
                label={t('category')}
                onChange={handleChangeCategory}
              >
                {categories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('price')}
              {...getFieldProps('price')}
              error={Boolean(touched.price && errors.price)}
              helperText={touched.price && errors.price}
            />
            <MultipleSelect formState={formState} setFormState={setFormState} items={tags} label={t('tags')} />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('priceDetails')}
              {...getFieldProps('priceDetails')}
              error={Boolean(touched.priceDetails && errors.priceDetails)}
              helperText={touched.priceDetails && errors.priceDetails}
            />
            <TextField
              fullWidth
              label={t('discount')}
              {...getFieldProps('discount')}
              error={Boolean(touched.discount && errors.discount)}
              helperText={touched.discount && errors.discount}
            />
          </Stack>

          <TextField
            multiline
            fullWidth
            label={t('description')}
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
          />

          <Upload setImage={setImage} text={t('uploadImage')} />
          {image && (
            <Avatar
              src={URL.createObjectURL(image)}
              variant="square"
              sx={{ width: '10%', height: '100%' }}
            />
          )}

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            {t('add')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
