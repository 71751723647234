import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// material
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useFetch from 'use-http';
import { backendUrl } from '../../../index';
import { useContext } from '../../../context/general/context';
import { logout } from '../../../context/general/actions';
// ----------------------------------------------------------------------

export default function EditProfileForm() {
  const { get, post, put, response, loading, error } = useFetch(backendUrl);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state, dispatch } = useContext();

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(1, t('editProfileForm.firstNameTooShort'))
      .max(50, t('editProfileForm.firstNameTooLong'))
      .required(t('editProfileForm.firstNameRequired')),
    lastName: Yup.string()
      .min(1, t('editProfileForm.lastNameTooShort'))
      .max(50, t('editProfileForm.lastNameTooLong'))
      .required(t('editProfileForm.lastNameRequired')),
  });

  const formik = useFormik({
    initialValues: {
      firstName: state.userDetails.firstName || '',
      lastName: state.userDetails.lastName || '',
    },
    enableReinitialize: true,
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      await put(`users/${state.userDetails.sub}`, { ...values });
      dispatch({ type: 'GLOBAL_UPDATE' });
      logout(dispatch);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label={t('editProfileForm.firstName')}
            {...getFieldProps('firstName')}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
          />

          <TextField
            fullWidth
            label={t('editProfileForm.lastName')}
            {...getFieldProps('lastName')}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('editProfileForm.save')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
