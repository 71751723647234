import { useFormik } from 'formik';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Container, Stack, Typography, Button } from '@mui/material';
// context
import { useContext as useGeneralContext } from '../context/general/context';
import { useLanguage } from '../context/language/context';
import { useTranslation } from 'react-i18next';
// components
import Page from '../components/Page';
import { ProductList, ProductFilterSidebar, ProductSort } from '../components/_dashboard/products';

// ----------------------------------------------------------------------

export default function EcommerceShop() {
  const { t } = useTranslation();

  const { state: generalState } = useGeneralContext();
  const { currentRestaurantId } = generalState;

  const { state: langState } = useLanguage();
  const language = langState.language;

  const [openFilter, setOpenFilter] = useState(false);

  const formik = useFormik({
    initialValues: {
      gender: '',
      category: '',
      colors: '',
      priceRange: '',
      rating: ''
    },
    onSubmit: () => {
      setOpenFilter(false);
    }
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => setOpenFilter(true);
  const handleCloseFilter = () => setOpenFilter(false);

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  const navigate = useNavigate();

  return (
    <Page title={t('dashboard.productss.title')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('dashboard.productss.heading')}
          </Typography>
          <Button
            disabled={currentRestaurantId === ''}
            variant="contained"
            component={RouterLink}
            to="/dashboard/add-product"
            startIcon={<Icon icon={plusFill} />}
          >
            {t('dashboard.productss.addProductButton')}
          </Button>
        </Stack>

        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              formik={formik}
              isOpenFilter={openFilter}
              onResetFilter={handleResetFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort />
          </Stack>
        </Stack>

        <ProductList />
      </Container>
    </Page>
  );
}
