import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useFetch from 'use-http';
import { backendUrl } from '../../../index';
import { useContext } from '../../../context/general/context';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function AddFeatureForm() {
  const { state } = useContext();
  const { post } = useFetch(backendUrl);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t('tooShort'))
      .max(50, t('tooLong'))
      .required(t('nameRequired')),
    description: Yup.string()
      .min(10, t('tooShort'))
      .max(500, t('tooLong'))
      .required(t('descriptionRequired')),
    key: Yup.string()
      .min(2, t('tooShort'))
      .max(100, t('tooLong'))
      .required(t('keyRequired')),
    payloadType: Yup.string()
      .min(2, t('tooShort'))
      .max(30, t('tooLong'))
      .required(t('payloadTypeRequired')),
    restaurantId: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      key: '',
      payloadType: '',
      restaurantId: state.currentRestaurantId
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      await post(`features`, { ...values });
      navigate('/dashboard/manage-features', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('name')}
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              fullWidth
              label={t('key')}
              {...getFieldProps('key')}
              error={Boolean(touched.key && errors.key)}
              helperText={touched.key && errors.key}
            />
          </Stack>

          <TextField
            fullWidth
            label={t('payloadType')}
            {...getFieldProps('payloadType')}
            error={Boolean(touched.payloadType && errors.payloadType)}
            helperText={touched.payloadType && errors.payloadType}
          />

          <TextField
            fullWidth
            multiline
            label={t('description')}
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('add')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
