import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useFetch from 'use-http';
import { backendUrl } from '../../../index';
import { useContext } from '../../../context/general/context';

export default function AddTableForm() {
  const { t } = useTranslation();
  const { state } = useContext();
  const { post } = useFetch(backendUrl);
  const navigate = useNavigate();

  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, t('tableNumberTooShort'))
      .max(3, t('tableNumberTooLong'))
      .required(t('tableNumberRequired')),
    restaurantId: Yup.string().required(t('restaurantIdRequired')),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      restaurantId: state.currentRestaurantId,
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      await post('tables', { ...values });
      navigate('/dashboard/tables', { replace: true });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('tableNumber')}
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('add')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
