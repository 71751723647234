import { Button } from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function AddRestaurantButton() {
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      component={RouterLink}
      to="/dashboard/add-restaurant"
      startIcon={<Icon icon={plusFill} />}
    >
      {t('dashboard.addRestaurant')}
    </Button>
  );
}
