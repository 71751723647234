import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AddRestaurantForm from '../components/_dashboard/restaurants/AddRestaurantForm';
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function AddRestaurant() {
  const [openFilter, setOpenFilter] = useState(false);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      gender: '',
      category: '',
      colors: '',
      priceRange: '',
      rating: ''
    },
    onSubmit: () => {
      setOpenFilter(false);
    }
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  const navigate = useNavigate();

  return (
    <Page title={t('dashboard.restaurants')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('dashboard.addRestaurant')}
          </Typography>
        </Stack>

        <AddRestaurantForm />
      </Container>
    </Page>
  );
}

