import {
  Box,
  CircularProgress,
  Grid,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
// context
import { useContext as useGeneralContext } from '../../../context/general/context';
import { useLanguage } from '../../../context/language/context';
import { backendUrl } from '../../../index';
import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid,
  margin: `0 ${grid}px 0 0`,
  ...draggableStyle
});
const getListStyle = (isDraggingOver) => ({
  display: 'flex',
  padding: grid,
  overflow: 'auto'
});

export default function ProductList({ ...other }) {
  const { t } = useTranslation();

  const { state: generalState } = useGeneralContext();
  const { currentRestaurantId, position, globalUpdate } = generalState;

  const { state: langState } = useLanguage();
  const language = langState.language;

  const [restaurant, setRestaurant] = useState();
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);

  async function loadProducts() {
    if (currentRestaurantId !== '') {
      setLoading(true);
      const response = await fetch(
        `${backendUrl}/restaurants/${currentRestaurantId}?showAll=true${
          language === 'ro' ? '' : `&lang=${language}`
        }`
      );
      const json = await response.json();
      if (response.ok) {
        setRestaurant(json);
      } else {
        setRestaurant({ categories: [] });
      }
      setLoading(false);
      window.scrollTo(0, position);
    } else {
      setRestaurant({ categories: [] });
    }

    setLoading(false);
    window.scrollTo(0, position);
  }

  useEffect(() => {
    loadProducts();
  }, [currentRestaurantId, updated, globalUpdate, language]);

  if (!restaurant?.categories)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress size={70} />
      </Box>
    );

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  async function onDragEnd(result, products) {
    if (!result.destination) return;

    const items = reorder(products, result.source.index, result.destination.index);
    const body = items.map((elem, index) => ({
      id: elem.id,
      priority: index
    }));

    setLoading(true);
    await fetch(`${backendUrl}/products`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    setUpdated(Date.now());
  }

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );

  return (
    <>
      {!currentRestaurantId && (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <Typography sx={{ fontSize: 36 }}>{t('productss.noRestaurant')}</Typography>
        </Box>
      )}

      <Grid container spacing={3} {...other}>
        {restaurant.categories.map((category) => (
          <Grid key={category.id} item xs={12} sm={12} md={12}>
            <Box mb={4}>
              <Typography fontSize={28}>{category.name}</Typography>
            </Box>

            <DragDropContext onDragEnd={(result) => onDragEnd(result, category.products)}>
              <Droppable direction="horizontal" droppableId={category.id}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {category.products.map((product, index) => (
                      <Draggable key={product.id} draggableId={product.id} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <ShopProductCard product={product} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
