import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// material
import { LoadingButton } from '@mui/lab';
import { Avatar, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import { useContext as useGeneralContext } from '../../../context/general/context';
import { useLanguage } from '../../../context/language/context';
import uploadTaskPromise, { backendUrl } from '../../../index';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import MultipleSelect from './MultipleSelect';
import Upload from './uploader/Upload';

export default function EditProductForm({ product }) {
  const { t } = useTranslation();
  const { put } = useFetch(backendUrl);
  const navigate = useNavigate();

  const { state: generalState } = useGeneralContext();
  const { currentRestaurantId } = generalState;

  const { state: langState } = useLanguage();
  const language = langState.language;

  const [image, setImage] = useState();
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(product.categoryId);
  const [newTags, setNewTags] = useState([]);

  const [formState, setFormState] = useState({
    items: product.tags.map((tag) => tag.id)
  });

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  async function uploadImage() {
    return await uploadTaskPromise(image, currentRestaurantId);
  }

  useEffect(() => {
    async function fetchCategories() {
      if (currentRestaurantId) {
        const response = await fetch(
          `${backendUrl}/restaurants/${currentRestaurantId}/categories${
            language === 'ro' ? '' : `?lang=${language}`
          }`
        );
        const json = await response.json();
        if (response.ok) {
          setCategories(json);
        } else {
          setCategories([]);
        }
      }
    }
    fetchCategories();
  }, [currentRestaurantId, language]);

  useEffect(() => {
    async function fetchTags() {
      const response = await fetch(`${backendUrl}/tags`);
      const json = await response.json();
      if (response.ok) {
        setNewTags(json);
      }
    }
    fetchTags();
  }, [currentRestaurantId]);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t('nameTooShort'))
      .max(100, t('nameTooLong'))
      .required(t('nameRequired')),
    categoryId: Yup.string()
      .required(t('categoryIdRequired')),
    price: Yup.number()
      .min(0, t('priceTooLow'))
      .required(t('priceRequired')),
    priceDetails: Yup.string()
      .max(100, t('priceDetailsTooLong'))
      .nullable(),
    discount: Yup.number()
      .min(0, t('minDiscount'))
      .max(100, t('maxDiscount'))
      .nullable(),
    description: Yup.string()
      .min(10, t('descriptionTooShort'))
      .max(1000, t('descriptionTooLong'))
      .required(t('descriptionRequired')),
    imgUrl: Yup.string()
      .max(255, t('urlTooLong'))
      .required(t('imageRequired')),
    tags: Yup.string()
  });

  const { id, name, imgUrl, price, priceDetails, discount, categoryId: initialCategoryId, description, tags } =
    product;

  const formik = useFormik({
    initialValues: {
      name,
      categoryId: initialCategoryId,
      restaurantId: currentRestaurantId,
      price,
      priceDetails,
      discount,
      description,
      imgUrl,
      tags: JSON.stringify(tags)
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      const newImageUrl = image ? await uploadImage() : imgUrl;
      await put(`products/${id}${language === 'ro' ? '' : `?lang=${language}`}`, {
        ...values,
        categoryId: category || (categories[0] && categories[0].id),
        imgUrl: newImageUrl,
        tags: formState.items
      });
      navigate('/dashboard/products', { replace: true });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('name')}
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <FormControl fullWidth>
              <InputLabel>{t('category')}</InputLabel>
              <Select
                value={category || ''}
                onChange={handleChangeCategory}
                label={t('category')}
              >
                {categories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('price')}
              {...getFieldProps('price')}
              error={Boolean(touched.price && errors.price)}
              helperText={touched.price && errors.price}
            />

            <MultipleSelect formState={formState} setFormState={setFormState} items={newTags} label={t('tags')} />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('priceDetails')}
              {...getFieldProps('priceDetails')}
              error={Boolean(touched.priceDetails && errors.priceDetails)}
              helperText={touched.priceDetails && errors.priceDetails}
            />

            <TextField
              fullWidth
              label={t('discount')}
              {...getFieldProps('discount')}
              error={Boolean(touched.discount && errors.discount)}
              helperText={touched.discount && errors.discount}
            />
          </Stack>

          <TextField
            multiline
            fullWidth
            label={t('description')}
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
          />

          <Upload setImage={setImage} text={t('changeImage')} />
          {image ? (
            <Avatar src={URL.createObjectURL(image)} variant="square" sx={{ width: '10%', height: '100%' }} />
          ) : (
            <Avatar src={imgUrl} variant="square" sx={{ width: '10%', height: '100%' }} />
          )}

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            {t('save')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
