// material
import { Box, Container, Grid, Typography } from '@mui/material';
// components
import CircularProgress from '@mui/material/CircularProgress';
import Page from '../components/Page';
import {
  AppTasks,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  UniqueTableVisitorsCard,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
  CurrentOrders,
  UniqueOnlineVisitorsCard,
  GraphWebsiteVisitsByDay,
  GraphScansByDay
} from '../components/_dashboard/app';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useContext } from '../context/general/context';
import { backendUrl } from '../index';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { t } = useTranslation();
  const [restaurant, setRestaurant] = useState();
  const { state } = useContext();
  const [loading, setLoading] = useState(false);
  const language = state.language;

  useEffect(() => {
    const fetchRestaurantData = async () => {
      if (state.currentRestaurantId) {
        setLoading(true);
        const response = await fetch(
          `${backendUrl}/restaurants/${state.currentRestaurantId}?simple=true${
            language === 'ro' ? '' : `&lang=${language}`
          }`
        );
        const json = await response.json();
        if (response.ok) {
          setRestaurant(json);
        }
        setLoading(false);
      }
    };

    fetchRestaurantData();
    window.scrollTo(0, 0);
  }, [state.currentRestaurantId, state.globalUpdate, state.language]);

  if (!state.currentRestaurantId)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography sx={{ fontSize: 36 }}>{t('dashboard.noRestaurant')}</Typography>
      </Box>
    );

  if (!restaurant || loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress size={70} />
      </Box>
    );

  return (
    <Page title={t('dashboard.pageTitle')}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">{t('dashboard.welcomeMessage')}</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <UniqueTableVisitorsCard restaurant={restaurant} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <UniqueOnlineVisitorsCard restaurant={restaurant} />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <GraphScansByDay restaurant={restaurant} />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <GraphWebsiteVisitsByDay restaurant={restaurant} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}