import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  Stack,
  Avatar,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useFetch from 'use-http';
import uploadTaskPromise, { backendUrl } from '../../../index';
import { useContext } from '../../../context/general/context';
import { changeCurrentRestaurant } from '../../../context/general/actions';
import Upload from '../products/uploader/Upload';

export default function EditRestaurantForm({ restaurant }) {
  const { t } = useTranslation();
  const { get, post, put } = useFetch(backendUrl);
  const navigate = useNavigate();
  const { state, dispatch } = useContext();

  const [imgUrlProfile, setImgUrlProfile] = useState();
  const [imgUrlCover, setImgUrlCover] = useState();
  const [imageProfile, setImageProfile] = useState();
  const [imageCover, setImageCover] = useState();

  const {
    id,
    urlKey,
    name,
    profileUrlImg: initialProfileUrlImg,
    coverUrlImg: initialCoverUrlImg,
    description,
    ssid,
    wifiPassword,
    address,
    latitude,
    longitude,
    email,
    phone,
    program,
    programEntries
  } = restaurant;

  const initialProgramEntries = {
    mondayBegin: programEntries?.mondayBegin || '',
    mondayEnd: programEntries?.mondayEnd || '',
    tuesdayBegin: programEntries?.tuesdayBegin || '',
    tuesdayEnd: programEntries?.tuesdayEnd || '',
    wednesdayBegin: programEntries?.wednesdayBegin || '',
    wednesdayEnd: programEntries?.wednesdayEnd || '',
    thursdayBegin: programEntries?.thursdayBegin || '',
    thursdayEnd: programEntries?.thursdayEnd || '',
    fridayBegin: programEntries?.fridayBegin || '',
    fridayEnd: programEntries?.fridayEnd || '',
    saturdayBegin: programEntries?.saturdayBegin || '',
    saturdayEnd: programEntries?.saturdayEnd || '',
    sundayBegin: programEntries?.sundayBegin || '',
    sundayEnd: programEntries?.sundayEnd || ''
  };

  function allDaysHaveSameSchedule(entries) {
    const zile = [
      { begin: entries.mondayBegin, end: entries.mondayEnd },
      { begin: entries.tuesdayBegin, end: entries.tuesdayEnd },
      { begin: entries.wednesdayBegin, end: entries.wednesdayEnd },
      { begin: entries.thursdayBegin, end: entries.thursdayEnd },
      { begin: entries.fridayBegin, end: entries.fridayEnd },
      { begin: entries.saturdayBegin, end: entries.saturdayEnd },
      { begin: entries.sundayBegin, end: entries.sundayEnd }
    ];
    const firstDay = zile[0];
    return zile.every(z => z.begin === firstDay.begin && z.end === firstDay.end);
  }

  let initialProgramGeneralBegin = '';
  let initialProgramGeneralEnd = '';
  if (
    allDaysHaveSameSchedule(initialProgramEntries) &&
    initialProgramEntries.mondayBegin &&
    initialProgramEntries.mondayEnd
  ) {
    initialProgramGeneralBegin = initialProgramEntries.mondayBegin;
    initialProgramGeneralEnd = initialProgramEntries.mondayEnd;
  }

  const language = state.language;

  const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
  const timeFormatMessage =
    t('timeFormatError') || 'Ora trebuie să fie în format HH:MM (00:00 - 23:59)';
  const errorRequiredEndIfStart = t('errorRequiredEndIfStart');
  const errorRequiredStartIfEnd = t('errorRequiredStartIfEnd');
  const fieldRequiredSameSchedule = t('fieldRequiredWhenSameSchedule');

  function dayBeginField(day) {
    return Yup.string()
      .nullable(true)
      .matches(timeRegex, timeFormatMessage)
      .test(
        `required-if-${day}End-exists`,
        errorRequiredStartIfEnd,
        function (value) {
          const siblingVal = this.parent[`${day}End`];
          if (siblingVal && !value) return false;
          return true;
        }
      );
  }

  function dayEndField(day) {
    return Yup.string()
      .nullable(true)
      .matches(timeRegex, timeFormatMessage)
      .test(
        `required-if-${day}Begin-exists`,
        errorRequiredEndIfStart,
        function (value) {
          const siblingVal = this.parent[`${day}Begin`];
          if (siblingVal && !value) return false;
          return true;
        }
      );
  }

  const RegisterSchema = Yup.object().shape({
    urlKey: Yup.string().max(50, t('urlKeyTooLong')),
    name: Yup.string()
      .min(2, t('restaurantNameTooShort'))
      .max(50, t('restaurantNameTooLong'))
      .required(t('restaurantNameRequired')),
    profileUrlImg: Yup.string().max(255, t('urlTooLong')),
    coverUrlImg: Yup.string().max(255, t('urlTooLong')),
    description: Yup.string()
      .min(10, t('descriptionTooShort'))
      .max(500, t('descriptionTooLong'))
      .required(t('descriptionRequired')),
    ssid: Yup.string().max(32, t('wifiNameTooLong')).nullable(),
    wifiPassword: Yup.string().max(64, t('wifiPasswordTooLong')).nullable(),
    address: Yup.string().max(100, t('addressTooLong')).nullable(),
    latitude: Yup.string().max(20, t('latitudeTooLong')).nullable(),
    longitude: Yup.string().max(20, t('longitudeTooLong')).nullable(),
    email: Yup.string().email(t('invalidEmail')).max(100, t('emailTooLong')).nullable(),
    phone: Yup.string().max(15, t('phoneTooLong')).nullable(),
    program: Yup.string().max(500, t('programTooLong')).nullable(),
    applySameForAllDays: Yup.boolean(),
    programGeneralBegin: Yup.string().when('applySameForAllDays', {
      is: true,
      then: Yup.string()
        .matches(timeRegex, timeFormatMessage)
        .required(fieldRequiredSameSchedule)
    }),
    programGeneralEnd: Yup.string().when('applySameForAllDays', {
      is: true,
      then: Yup.string()
        .matches(timeRegex, timeFormatMessage)
        .required(fieldRequiredSameSchedule)
    }),
    programEntries: Yup.object().shape({
      mondayBegin: dayBeginField('monday'),
      mondayEnd: dayEndField('monday'),
      tuesdayBegin: dayBeginField('tuesday'),
      tuesdayEnd: dayEndField('tuesday'),
      wednesdayBegin: dayBeginField('wednesday'),
      wednesdayEnd: dayEndField('wednesday'),
      thursdayBegin: dayBeginField('thursday'),
      thursdayEnd: dayEndField('thursday'),
      fridayBegin: dayBeginField('friday'),
      fridayEnd: dayEndField('friday'),
      saturdayBegin: dayBeginField('saturday'),
      saturdayEnd: dayEndField('saturday'),
      sundayBegin: dayBeginField('sunday'),
      sundayEnd: dayEndField('sunday')
    })
  });

  async function uploadImage(image, setImgUrl) {
    const imageUrl = await uploadTaskPromise(image, state.currentRestaurantId);
    setImgUrl(imageUrl);
    return imageUrl;
  }

  const formik = useFormik({
    initialValues: {
      name: name || '',
      urlKey: urlKey || '',
      profileUrlImg: initialProfileUrlImg || '',
      coverUrlImg: initialCoverUrlImg || '',
      description: description || '',
      ssid: ssid || '',
      wifiPassword: wifiPassword || '',
      address: address || '',
      latitude: latitude || '',
      longitude: longitude || '',
      email: email || '',
      phone: phone || '',
      program: program || '',
      applySameForAllDays: !!(initialProgramGeneralBegin && initialProgramGeneralEnd),
      programGeneralBegin: initialProgramGeneralBegin,
      programGeneralEnd: initialProgramGeneralEnd,
      programEntries: initialProgramEntries
    },
    enableReinitialize: true,
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      try {
        let newUrlProfile = initialProfileUrlImg;
        let newUrlCover = initialCoverUrlImg;

        if (imageProfile) {
          newUrlProfile = await uploadImage(imageProfile, setImgUrlProfile);
        }
        if (imageCover) {
          newUrlCover = await uploadImage(imageCover, setImgUrlCover);
        }

        const finalValues = { ...values };

        if (values.applySameForAllDays) {
          const begin = values.programGeneralBegin;
          const end = values.programGeneralEnd;
          finalValues.programEntries = {
            mondayBegin: begin,
            mondayEnd: end,
            tuesdayBegin: begin,
            tuesdayEnd: end,
            wednesdayBegin: begin,
            wednesdayEnd: end,
            thursdayBegin: begin,
            thursdayEnd: end,
            fridayBegin: begin,
            fridayEnd: end,
            saturdayBegin: begin,
            saturdayEnd: end,
            sundayBegin: begin,
            sundayEnd: end
          };
        }

        finalValues.profileUrlImg = newUrlProfile;
        finalValues.coverUrlImg = newUrlCover;

        const response = await fetch(
          backendUrl + `/restaurants/${id}`,
          {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(finalValues)
          }
        );

        if (response.ok) {
          dispatch({ type: 'GLOBAL_UPDATE' });
          navigate('/dashboard/edit-restaurant', { replace: true });
        } else {
          console.error('Error updating restaurant:', await response.text());
        }
      } catch (error) {
        console.error('Error updating restaurant:', error);
      }
    }
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('name')}
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              fullWidth
              label={t('urlName')}
              {...getFieldProps('urlKey')}
              error={Boolean(touched.urlKey && errors.urlKey)}
              helperText={touched.urlKey && errors.urlKey}
            />
          </Stack>

          <TextField
            fullWidth
            label={t('description')}
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
          />

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Upload setImage={setImageProfile} text={t('changeProfilePicture')} />
            {imageProfile ? (
              <Avatar
                src={URL.createObjectURL(imageProfile)}
                variant="square"
                sx={{ width: '10%', height: '100%' }}
              />
            ) : (
              <Avatar
                src={initialProfileUrlImg}
                variant="square"
                sx={{ width: '10%', height: '100%' }}
              />
            )}

            <Upload setImage={setImageCover} text={t('changeCoverPicture')} />
            {imageCover ? (
              <Avatar
                src={URL.createObjectURL(imageCover)}
                variant="square"
                sx={{ width: '10%', height: '100%' }}
              />
            ) : (
              <Avatar
                src={initialCoverUrlImg}
                variant="square"
                sx={{ width: '10%', height: '100%' }}
              />
            )}
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('wifiName')}
              {...getFieldProps('ssid')}
              error={Boolean(touched.ssid && errors.ssid)}
              helperText={touched.ssid && errors.ssid}
            />

            <TextField
              fullWidth
              label={t('wifiPassword')}
              {...getFieldProps('wifiPassword')}
              error={Boolean(touched.wifiPassword && errors.wifiPassword)}
              helperText={touched.wifiPassword && errors.wifiPassword}
            />
          </Stack>

          <TextField
            fullWidth
            label={t('address')}
            {...getFieldProps('address')}
            error={Boolean(touched.address && errors.address)}
            helperText={touched.address && errors.address}
          />

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('latitude')}
              {...getFieldProps('latitude')}
              error={Boolean(touched.latitude && errors.latitude)}
              helperText={touched.latitude && errors.latitude}
            />

            <TextField
              fullWidth
              label={t('longitude')}
              {...getFieldProps('longitude')}
              error={Boolean(touched.longitude && errors.longitude)}
              helperText={touched.longitude && errors.longitude}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('email')}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              label={t('phone')}
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
          </Stack>

          <TextField
            fullWidth
            multiline
            rows={2}
            label={t('generalSchedule')}
            {...getFieldProps('program')}
            error={Boolean(touched.program && errors.program)}
            helperText={touched.program && errors.program}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={values.applySameForAllDays}
                onChange={(e) => setFieldValue('applySameForAllDays', e.target.checked)}
              />
            }
            label={t('applySameForAllDays')}
          />

          <Stack spacing={2}>
            <Typography variant="h6">{t('detailedSchedule')}</Typography>

            {values.applySameForAllDays ? (
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label={t('startInterval')}
                  {...getFieldProps('programGeneralBegin')}
                  error={Boolean(touched.programGeneralBegin && errors.programGeneralBegin)}
                  helperText={touched.programGeneralBegin && errors.programGeneralBegin}
                />
                <TextField
                  fullWidth
                  label={t('endInterval')}
                  {...getFieldProps('programGeneralEnd')}
                  error={Boolean(touched.programGeneralEnd && errors.programGeneralEnd)}
                  helperText={touched.programGeneralEnd && errors.programGeneralEnd}
                />
              </Stack>
            ) : (
              <>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label={t('mondayStart')}
                    {...getFieldProps('programEntries.mondayBegin')}
                    error={Boolean(
                      touched.programEntries?.mondayBegin &&
                        errors.programEntries?.mondayBegin
                    )}
                    helperText={
                      touched.programEntries?.mondayBegin &&
                      errors.programEntries?.mondayBegin
                    }
                  />
                  <TextField
                    fullWidth
                    label={t('mondayEnd')}
                    {...getFieldProps('programEntries.mondayEnd')}
                    error={Boolean(
                      touched.programEntries?.mondayEnd && errors.programEntries?.mondayEnd
                    )}
                    helperText={
                      touched.programEntries?.mondayEnd && errors.programEntries?.mondayEnd
                    }
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label={t('tuesdayStart')}
                    {...getFieldProps('programEntries.tuesdayBegin')}
                    error={Boolean(
                      touched.programEntries?.tuesdayBegin &&
                        errors.programEntries?.tuesdayBegin
                    )}
                    helperText={
                      touched.programEntries?.tuesdayBegin && errors.programEntries?.tuesdayBegin
                    }
                  />
                  <TextField
                    fullWidth
                    label={t('tuesdayEnd')}
                    {...getFieldProps('programEntries.tuesdayEnd')}
                    error={Boolean(
                      touched.programEntries?.tuesdayEnd && errors.programEntries?.tuesdayEnd
                    )}
                    helperText={
                      touched.programEntries?.tuesdayEnd && errors.programEntries?.tuesdayEnd
                    }
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label={t('wednesdayStart')}
                    {...getFieldProps('programEntries.wednesdayBegin')}
                    error={Boolean(
                      touched.programEntries?.wednesdayBegin &&
                        errors.programEntries?.wednesdayBegin
                    )}
                    helperText={
                      touched.programEntries?.wednesdayBegin && errors.programEntries?.wednesdayBegin
                    }
                  />
                  <TextField
                    fullWidth
                    label={t('wednesdayEnd')}
                    {...getFieldProps('programEntries.wednesdayEnd')}
                    error={Boolean(
                      touched.programEntries?.wednesdayEnd && errors.programEntries?.wednesdayEnd
                    )}
                    helperText={
                      touched.programEntries?.wednesdayEnd && errors.programEntries?.wednesdayEnd
                    }
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label={t('thursdayStart')}
                    {...getFieldProps('programEntries.thursdayBegin')}
                    error={Boolean(
                      touched.programEntries?.thursdayBegin &&
                        errors.programEntries?.thursdayBegin
                    )}
                    helperText={
                      touched.programEntries?.thursdayBegin && errors.programEntries?.thursdayBegin
                    }
                  />
                  <TextField
                    fullWidth
                    label={t('thursdayEnd')}
                    {...getFieldProps('programEntries.thursdayEnd')}
                    error={Boolean(
                      touched.programEntries?.thursdayEnd &&
                        errors.programEntries?.thursdayEnd
                    )}
                    helperText={
                      touched.programEntries?.thursdayEnd && errors.programEntries?.thursdayEnd
                    }
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label={t('fridayStart')}
                    {...getFieldProps('programEntries.fridayBegin')}
                    error={Boolean(
                      touched.programEntries?.fridayBegin &&
                        errors.programEntries?.fridayBegin
                    )}
                    helperText={
                      touched.programEntries?.fridayBegin && errors.programEntries?.fridayBegin
                    }
                  />
                  <TextField
                    fullWidth
                    label={t('fridayEnd')}
                    {...getFieldProps('programEntries.fridayEnd')}
                    error={Boolean(
                      touched.programEntries?.fridayEnd && errors.programEntries?.fridayEnd
                    )}
                    helperText={
                      touched.programEntries?.fridayEnd && errors.programEntries?.fridayEnd
                    }
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label={t('saturdayStart')}
                    {...getFieldProps('programEntries.saturdayBegin')}
                    error={Boolean(
                      touched.programEntries?.saturdayBegin &&
                        errors.programEntries?.saturdayBegin
                    )}
                    helperText={
                      touched.programEntries?.saturdayBegin &&
                      errors.programEntries?.saturdayBegin
                    }
                  />
                  <TextField
                    fullWidth
                    label={t('saturdayEnd')}
                    {...getFieldProps('programEntries.saturdayEnd')}
                    error={Boolean(
                      touched.programEntries?.saturdayEnd &&
                        errors.programEntries?.saturdayEnd
                    )}
                    helperText={
                      touched.programEntries?.saturdayEnd &&
                      errors.programEntries?.saturdayEnd
                    }
                  />
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextField
                    fullWidth
                    label={t('sundayStart')}
                    {...getFieldProps('programEntries.sundayBegin')}
                    error={Boolean(
                      touched.programEntries?.sundayBegin && errors.programEntries?.sundayBegin
                    )}
                    helperText={
                      touched.programEntries?.sundayBegin && errors.programEntries?.sundayBegin
                    }
                  />
                  <TextField
                    fullWidth
                    label={t('sundayEnd')}
                    {...getFieldProps('programEntries.sundayEnd')}
                    error={Boolean(
                      touched.programEntries?.sundayEnd && errors.programEntries?.sundayEnd
                    )}
                    helperText={
                      touched.programEntries?.sundayEnd && errors.programEntries?.sundayEnd
                    }
                  />
                </Stack>
              </>
            )}
          </Stack>

          <LoadingButton
            disabled={!state.currentRestaurantId}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('save')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}