import React, { createContext, useContext, useReducer } from 'react';
import i18n from '../../i18n';

const LanguageContext = createContext();

const storedLanguage =
  typeof window !== 'undefined' ? localStorage.getItem('language') : null;

const initialState = {
  language: storedLanguage || 'ro',
};

function languageReducer(state, action) {
  switch (action.type) {
    case 'SET_LANGUAGE':
      i18n.changeLanguage(action.payload);

      if (typeof window !== 'undefined') {
        localStorage.setItem('language', action.payload);
      }

      return {
        ...state,
        language: action.payload,
      };

    default:
      return state;
  }
}

export function LanguageProvider({ children }) {
  const [state, dispatch] = useReducer(languageReducer, initialState);

  return (
    <LanguageContext.Provider value={{ state, dispatch }}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  return useContext(LanguageContext);
}
