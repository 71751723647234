import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useContext } from '../../../context/general/context';

// material
import {
  CircularProgress,
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Button,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@mui/material';
import { backendUrl } from '../../../index';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

export function AdminFeatureAddListToolbar({ numSelected, filterName, onFilterName, featureId, selected, setSelected }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext();

  async function activate() {
    setLoading(true);

    await fetch(backendUrl + `/restaurants-features`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(selected.map((id) => ({ featureId, restaurantId: id })))
    });

    setLoading(false);
    setSelected([]);
    dispatch({ type: 'GLOBAL_UPDATE' });
  }

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {t('selected', { count: numSelected })}
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder={t('search') + '...'}
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 ? (
        <Tooltip title={t('add')}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button onClick={activate} variant="contained" color="info">
              {t('activate')}
            </Button>
          )}
        </Tooltip>
      ) : (
        <Tooltip title={t('filterList')}>
          <IconButton>
            <Icon icon={roundFilterList} />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}

export function AdminFeatureRemoveListToolbar({ numSelected, filterName, onFilterName, selected, setSelected }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext();

  async function deactivate() {
    setLoading(true);

    await fetch(backendUrl + `/restaurants-features`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(selected.map((id) => ({ restaurantFeatureId: id })))
    });

    setLoading(false);
    setSelected([]);
    dispatch({ type: 'GLOBAL_UPDATE' });
  }

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {t('selected', { count: numSelected })}
        </Typography>
      ) : (
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder={t('search') + '...'}
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 ? (
        <Tooltip title={t('remove')}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button onClick={deactivate} variant="contained" color="error">
              {t('remove')}
            </Button>
          )}
        </Tooltip>
      ) : (
        <Tooltip title={t('filterList')}>
          <IconButton>
            <Icon icon={roundFilterList} />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
