// material
import { useState, useEffect } from 'react';
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { useContext } from '../context/general/context';
import { backendUrl } from '../index';
import TableSquare from '../components/_dashboard/orders/TableSquare';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function TableOrders() {
  const [tables, setTables] = useState();
  const { state } = useContext();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchTables = async () => {
      if (state.currentRestaurantId !== "") {
        const response = await fetch(
          backendUrl + `/restaurants/${state.currentRestaurantId}/tables`
        );
        const json = await response.json();
        if (response.ok) {
          setTables(json);
        }
      } else {
        setTables([]);
      }
    };

    fetchTables();
  }, [state.currentRestaurantId]);

  if (!tables) {
    return <Typography>{t('loading')}</Typography>;
  }

  return (
    <Page title={t('dashboard.title')}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">{t('dashboard.welcomeMessagee')}</Typography>
        </Box>
        <Grid container spacing={3}>
          {tables.map((table) => (
            <Grid item xs={12} sm={6} md={3} key={table.id}>
              <TableSquare name={table.name} id={table.id} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
}
