import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
// material
import { useNavigate } from "react-router-dom";
import {
  Container,
  Stack,
  Typography,
  CircularProgress,
  Box,
  Link
} from '@mui/material';
import EditRestaurantForm from '../components/_dashboard/restaurants/EditRestaurantForm';

// components
import Page from '../components/Page';
import QRButton from '../components/_dashboard/tables/qr/QRButton';

import { useContext } from '../context/general/context';
import { backendUrl } from '../index';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function EditRestaurant() {
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = useState(false);
  const [restaurant, setRestaurant] = useState();
  const { state, dispatch } = useContext();
  const [loading, setLoading] = useState(false);
  const language = state.language;

  useEffect(() => {
    let isMounted = true;

    const fetchRestaurant = async () => {
      if (state.currentRestaurantId) {
        setLoading(true);
        try {
          const response = await fetch(
            backendUrl +
              '/restaurants/' + state.currentRestaurantId + "?simple=true"
          );
          const json = await response.json();
          if (response.ok && isMounted) {
            setRestaurant(json);
            setLoading(false);
          }
        } catch (error) {
          console.error('Error fetching restaurant:', error);
          if (isMounted) {
            setLoading(false);
          }
        }
      }
    };

    fetchRestaurant();
    window.scrollTo(0, 0);

    return () => {
      isMounted = false;
    };
  }, [state.currentRestaurantId, state.globalUpdate, state.language]);

  if (!state.currentRestaurantId)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography sx={{ fontSize: 36 }}>{t('dashboard.addRestaurant')}</Typography>
      </Box>
    );

  if (!restaurant)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size={70} />
      </Box>
    );

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size={70} />
      </Box>
    );

  console.log("Restaurant parinte");
  console.log(restaurant);
  return (
    <Page title={t('dashboardProducts')}>
      <Container>
        <Box display="flex" flexDirection="row">
          <Box display="flex" flexDirection="column">
            <Box mr={2}>
              <a
                href={`https://www.reach-menu.com/${restaurant.urlKey}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography>
                  {`https://www.reach-menu.com/${restaurant.urlKey}`}
                </Typography>
              </a>
            </Box>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography variant="h4" gutterBottom>
                {t('editrestaurant')}
              </Typography>
            </Stack>
          </Box>

          <Box>
            <QRButton />
          </Box>
        </Box>

        <EditRestaurantForm restaurant={restaurant} />
      </Container>
    </Page>
  );
}
