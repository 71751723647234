import { Icon } from '@iconify/react';
import pieChartOutline from '@iconify/icons-eva/pie-chart-outline';
import peopleOutline from '@iconify/icons-eva/people-outline';
import fileTextOutline from '@iconify/icons-eva/file-text-outline';
import listFill from '@iconify/icons-eva/list-fill';
import homeOutline from '@iconify/icons-eva/home-outline';
import shoppingCartOutline from '@iconify/icons-eva/shopping-cart-outline';
import settingsOutline from '@iconify/icons-eva/settings-outline';
import gridOutline from '@iconify/icons-eva/grid-outline';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import options2Outline from '@iconify/icons-eva/options-2-outline';
import colorPaletteOutline from '@iconify/icons-eva/color-palette-outline';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import { useTranslation } from 'react-i18next';

const getIcon = (iconName) => <Icon icon={iconName} width={22} height={22} />;

export default function useSidebarConfig() {
  const { t } = useTranslation();

  return [
    {
      title: t('sidebar.statistics'),
      path: '/dashboard/app',
      icon: getIcon(pieChartOutline)
    },
    {
      title: t('sidebar.restaurant'),
      path: '/dashboard/edit-restaurant',
      icon: getIcon(homeOutline)
    },
    {
      title: t('sidebar.users'),
      path: '/dashboard/user',
      icon: getIcon(peopleOutline)
    },
    {
      title: t('sidebar.tables'),
      path: '/dashboard/tables',
      icon: getIcon(gridOutline)
    },
   /* {
      title: t('sidebar.tableOrders'),
      path: '/dashboard/table-orders',
      icon: getIcon(listFill)
    },*/
    {
      title: t('sidebar.orders'),
      path: '/dashboard/orders',
      icon: getIcon(fileTextOutline)
    },
    {
      title: t('sidebar.categories'),
      path: '/dashboard/categories',
      icon: getIcon(listFill)
    },
    {
      title: t('sidebar.products'),
      path: '/dashboard/products',
      icon: getIcon(shoppingCartOutline)
    },
    {
      title: t('sidebar.settings'),
      path: '/dashboard/features',
      icon: getIcon(settingsOutline)
    }
    /*{
      title: t('sidebar.manageFeatures'),
      path: '/dashboard/manage-features',
      icon: getIcon(options2Outline)
    },
    {
      title: t('sidebar.tags'),
      path: '/dashboard/taggs',
      icon: getIcon(colorPaletteOutline)
    },
    {
      title: t('sidebar.blog'),
      path: '/dashboard/blog',
      icon: getIcon(fileTextFill)
    },
    {
      title: t('sidebar.login'),
      path: '/login',
      icon: getIcon(lockFill)
    },
    {
      title: t('sidebar.register'),
      path: '/register',
      icon: getIcon(personAddFill)
    },
    {
      title: t('sidebar.notFound'),
      path: '/404',
      icon: getIcon(alertTriangleFill)
    }*/
  ];
}
