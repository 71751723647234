import { useState } from 'react';
import { Avatar, Button, Box } from '@mui/material';
import { useContext } from '../../../../context/general/context';
import { backendUrl } from '../../../../index';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

export default function QRButton({ tableId }) {
  const [qrImage, setqrImage] = useState();
  const { state } = useContext();
  const { t } = useTranslation();

  async function handleGenerateQR() {
    const response = await fetch(
      backendUrl +
        '/qr/' +
        (tableId
          ? `${state.currentRestaurantId}/tables/${tableId}`
          : `key/${state.currentRestaurantId}`)
    );
    const file = await response.blob();
    if (response.ok) {
      setqrImage(URL.createObjectURL(file));
    }
  }

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box>
          {!qrImage && (
            <Button
              startIcon={<Icon icon="mdi:qrcode-scan" />}
              variant="outlined"
              onClick={() => handleGenerateQR()}
            >
              {t('generateQR')}
            </Button>
          )}
        </Box>
        <Box>
          {qrImage ? <img src={qrImage} alt={t('qrCode')} /> : null}
        </Box>
      </Box>
    </>
  );
}
