import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Categories from './pages/Categories';
import AddProduct from './pages/AddProduct';
import EditProduct from './pages/EditProduct';
import AddTable from './pages/AddTable';
import AddCategory from './pages/AddCategory';
import AddRestaurant from './pages/AddRestaurant';
import AddFeature from './pages/AddFeature';
import EditRestaurant from './pages/EditRestaurant';
import AddUser from './pages/AddUser';
import EditUser from './pages/EditUser';
import Blog from './pages/Blog';
import User from './pages/User';
import Tables from './pages/Tables';
import Orders from './pages/Orders';
import TableOrders from './pages/TableOrders';
import Order from './pages/Order';
import OrderTable from './pages/OrderTable';
import Features from './pages/Features';
import ManageFeatures from './pages/ManageFeatures';
import AdminFeature from './pages/AdminFeature';
import Profile from './pages/Profile';
import Settings from './pages/Settings';

import NotFound from './pages/Page404';

// ----------------------------------------------------------------------
import {useContext} from './context/general/context'
export default function Router() {
  const {state} = useContext()
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: state.logged ? <DashboardApp /> : <Navigate to="/login" replace /> },
        { path: 'user', element: state.logged ? <User />  : <Navigate to="/login" replace /> },
        { path: 'tables', element: state.logged ? <Tables /> : <Navigate to="/login" replace />  },
        { path: 'table-orders', element: state.logged ? <TableOrders />  : <Navigate to="/login" replace /> },
        { path: 'orders', element: state.logged ? <Orders />  : <Navigate to="/login" replace /> },
        { path: 'table/:id', element: state.logged ? <OrderTable />: <Navigate to="/login" replace />  },
        { path: 'order/:id', element: state.logged ? <Order />: <Navigate to="/login" replace />  },
        { path: 'register', element: <Register /> },
        { path: 'edit-restaurant', element: state.logged ?<EditRestaurant /> : <Navigate to="/login" replace />    },
        { path: 'products', element: state.logged ?<Products /> : <Navigate to="/login" replace />    },
        { path: 'categories', element: state.logged ?<Categories /> : <Navigate to="/login" replace />    },
        { path: 'add-product', element: state.logged ? <AddProduct /> : <Navigate to="/login" replace /> },
        { path: 'edit-product', element: state.logged ? <EditProduct /> : <Navigate to="/login" replace /> },
        { path: 'add-table', element: state.logged ? <AddTable /> : <Navigate to="/login" replace />  },
        { path: 'add-category', element: state.logged ? <AddCategory /> : <Navigate to="/login" replace />  },
        { path: 'edit-category', element: state.logged ? <AddCategory /> : <Navigate to="/login" replace />  },
        { path: 'add-restaurant', element: state.logged ? <AddRestaurant /> : <Navigate to="/login" replace />  },
        { path: 'add-user', element: state.logged ? <AddUser />  : <Navigate to="/login" replace /> },
        { path: 'edit-user', element: state.logged ? <EditUser />  : <Navigate to="/login" replace /> },
        { path: 'features', element: state.logged ? <Features />  : <Navigate to="/login" replace /> },
        { path: 'manage-features', element: state.logged ? <ManageFeatures />  : <Navigate to="/login" replace /> },
        { path: 'add-feature', element: state.logged ? <AddFeature />  : <Navigate to="/login" replace /> },
        { path: 'admin-feature', element: state.logged ? <AdminFeature />  : <Navigate to="/login" replace /> },
        { path: 'profile', element: state.logged ? <Profile />  : <Navigate to="/login" replace /> },
        { path: 'settings', element: state.logged ? <Settings />  : <Navigate to="/login" replace /> },
        { path: 'blog', element: <Blog /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
