import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { backendUrl } from '../../../index';
import { useContext } from '../../../context/general/context';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteDialog({ open, setOpen, id, name }) {
  const [load, setLoad] = useState(false);
  const { state, dispatch } = useContext();
  const { t } = useTranslation();

  const handleClose = async () => {
    setLoad(true);
    await fetch(backendUrl + '/products/' + id, { method: 'DELETE' });
    setLoad(false);
    setOpen(false);
    dispatch({ type: 'GLOBAL_UPDATE' });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography sx={{ fontWeight: 'fontWeightBold' }}>
          {t('deleteConfirmation', { name })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ borderRadius: 0, backgroundColor: '#fff', color: '#000' }}
          variant="contained"
          onClick={() => setOpen(false)}
        >
          {t('cancel')}
        </Button>

        <Button
          sx={{ fontWeight: 'fontWeightBold', borderRadius: 0 }}
          color="secondary"
          disabled={load}
          variant="contained"
          onClick={handleClose}
        >
          {!load ? t('confirm') : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress sx={{ color: '#fff' }} size={20} />
            </Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}