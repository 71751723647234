import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress
} from '@mui/material';
import { useContext as useGeneralContext } from '../context/general/context';
import { backendUrl } from '../index';
// i18n
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../context/language/context';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import TablesListHead from '../components/_dashboard/tables/TablesListHead';
import CatMoreMenu from '../components/_dashboard/categories/CatMoreMenu';
import { DraggableComponent, DroppableComponent } from '../components/_dashboard/tables/DndTable';
import EditableCell from '../components/_dashboard/categories/EditableCell';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Categories() {
  const { t } = useTranslation();

  const { state: generalState } = useGeneralContext();
  const { currentRestaurantId, globalUpdate } = generalState;

  const { state: langState } = useLanguage();
  const language = langState.language;

  const [restaurant, setRestaurant] = useState();
  const [categories, setCategories] = useState();
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);

  const TABLE_HEAD = [
    { id: 'categoryName', label: 'categoryName', alignRight: false },
    { id: 'status', label: 'status', alignRight: false },
    { id: 'deliveryOnly', label: 'deliveryOnly', alignRight: false },
    { id: 'preview', label: 'preview', alignRight: false },
    { id: 'specialMentions', label: 'specialMentions', alignRight: false },
    { id: 'more', label: 'more', alignRight: true }
  ];

  useEffect(() => {
    async function loadData() {
      if (currentRestaurantId !== '') {
        setLoading(true);
        const response = await fetch(
          `${backendUrl}/restaurants/${currentRestaurantId}?showAll=true${
            language === 'ro' ? '' : `&lang=${language}`
          }`
        );
        const json = await response.json();
        if (response.ok) {
          setRestaurant(json);
          setCategories(json.categories);
        } else {
          setRestaurant({ categories: [] });
          setCategories([]);
        }
        setLoading(false);
      } else {
        setRestaurant({ categories: [] });
        setCategories([]);
      }
    }
    loadData();
  }, [currentRestaurantId, updated, language, globalUpdate]);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  async function onDragEnd(result) {
    if (!result.destination) return;

    const items = reorder(categories, result.source.index, result.destination.index);
    const body = items.map((elem, index) => ({
      id: elem.id,
      priority: index
    }));

    setLoading(true);
    await fetch(`${backendUrl}/categories`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    });
    setUpdated(Date.now());
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (!restaurant?.categories) return;

    if (event.target.checked) {
      const newSelecteds = restaurant.categories.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress size={70} />
      </Box>
    );

  if (!categories)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress size={70} />
      </Box>
    );

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categories.length) : 0;

  return (
    <Page title={t('categories.pageTitle')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('categories.title')}
          </Typography>

          <Button
            disabled={currentRestaurantId === ''}
            variant="contained"
            component={RouterLink}
            to="/dashboard/add-category"
            startIcon={<Icon icon={plusFill} />}
          >
            {t('categories.addCategory')}
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TablesListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={restaurant?.categories?.length || 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody component={DroppableComponent(onDragEnd)}>
                  {categories
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      const { id, name, deleted, deliveryOnly, quickCategory } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          component={DraggableComponent(id, i)}
                        >
                          <TableCell align="left">{i}</TableCell>

                          <EditableCell
                            endpoint={`/categories/${id}${language === 'ro' ? '' : `?lang=${language}`}`}
                            row={row}
                            column="name"
                          />

                          <TableCell align="left">
                            <Label variant="ghost" color={(deleted && 'error') || 'success'}>
                              {sentenceCase(
                                deleted
                                  ? t('categories.label.hidden')
                                  : t('categories.label.visible')
                              )}
                            </Label>
                          </TableCell>

                          <TableCell align="left">
                            <Label variant="ghost" color={deliveryOnly ? 'warning' : 'info'}>
                              {sentenceCase(
                                deliveryOnly
                                  ? t('categories.label.deliveryOnly')
                                  : t('categories.label.notDeliveryOnly')
                              )}
                            </Label>
                          </TableCell>

                          <TableCell align="left">
                            <Label variant="ghost" color={quickCategory ? 'warning' : 'info'}>
                              {sentenceCase(
                                quickCategory === true
                                  ? t('categories.label.reduced')
                                  : t('categories.label.extended')
                              )}
                            </Label>
                          </TableCell>

                          <EditableCell
                            endpoint={`/categories/${id}${language === 'ro' ? '' : `?lang=${language}`}`}
                            row={row}
                            column="specialWarnings"
                          />

                          <TableCell align="right">
                            <CatMoreMenu
                              categoryId={id}
                              setUpdated={setUpdated}
                              status={!deleted}
                              deliveryOnly={!deliveryOnly}
                              quickCategory={quickCategory}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            labelRowsPerPage={t('tablee.labelRowsPerPage')}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${t('tablee.of')} ${count !== -1 ? count : t('tablee.moreThan')}`
            }
            rowsPerPageOptions={[5, 10, 25, 50, 100, 1000]}
            component="div"
            count={restaurant?.categories?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
