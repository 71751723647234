import { Box, Grid, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Page from '../components/Page';
import {
  AppTasks, CurrentOrders, AppOrderTimeline
} from '../components/_dashboard/app';

// ----------------------------------------------------------------------

export default function Orders() {
  const { t } = useTranslation();

  return (
    <Page title={t('orders.pageTitle')}>
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">{t('orders.title')}</Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <CurrentOrders />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
