import { Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
// components
import Page from '../components/Page';
import EditProfileForm from '../components/_dashboard/user/EditProfileForm';

// ----------------------------------------------------------------------

export default function Profile() {
  const { t } = useTranslation();

  return (
    <Page title={t('dashboard.profile.pageTitle')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('dashboard.profile.editProfile')}
          </Typography>
        </Stack>

        <EditProfileForm />
      </Container>
    </Page>
  );
}
