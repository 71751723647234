import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import FeatureListHead from '../components/_dashboard/features/FeatureListHead';
import { backendUrl } from '../index';
import { useContext } from '../context/general/context';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', labelKey: 'manageFeatures.name', alignRight: false },
  { id: 'description', labelKey: 'manageFeatures.description', alignRight: false },
  { id: 'key', labelKey: 'manageFeatures.key', alignRight: false },
  { id: 'actions', labelKey: 'manageFeatures.actions', alignRight: true }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_feature) => _feature.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ManageFeatures() {
  const { t } = useTranslation();
  const [features, setFeatures] = useState();
  const { state } = useContext();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchFeatures() {
      if (state.currentRestaurantId) {
        const response = await fetch(`${backendUrl}/features`);
        const json = await response.json();
        if (response.ok) {
          setFeatures(json);
        }
      } else {
        setFeatures([]);
      }
    }
    fetchFeatures();
  }, [state.currentRestaurantId]);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  if (!features) return t('loading');

  const filteredFeatures = applySortFilter(features, getComparator(order, orderBy), filterName);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - features.length) : 0;

  return (
    <Page title={t('manageFeatures.title')}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {t('manageFeatures.header')}
          </Typography>
          <Button
            disabled={!state.currentRestaurantId}
            variant="contained"
            component={RouterLink}
            to="/dashboard/add-feature"
            startIcon={<Icon icon={plusFill} />}
          >
            {t('manageFeatures.addFeature')}
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <FeatureListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD.map((head) => ({
                    ...head,
                    label: t(head.labelKey)
                  }))}
                  rowCount={features.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredFeatures.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, name, description, key } = row;

                    return (
                      <TableRow hover key={id}>
                        <TableCell>{name}</TableCell>
                        <TableCell>{description}</TableCell>
                        <TableCell>{key}</TableCell>
                        <TableCell align="right">
                          <Button
                            disabled={!state.currentRestaurantId}
                            variant="contained"
                            color="info"
                            onClick={() => navigate('/dashboard/admin-feature', { state: row })}
                          >
                            {t('manageFeatures.manage')}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </TableBody>
                {filteredFeatures.length === 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={features.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
